<template>
    <div v-if="loading" class="text-center">
        <v-skeleton-loader
        type="actions, article@5"/>
    </div>
    
    <v-container v-else>
        <v-row justify="space-between">
            <v-col cols="12" sm="6" md="4"
            class="pt-3 pb-0 pb-sm-3">
                <v-text-field
                    label="Rechercher"
                    v-model="search"
                    hint="Chercher par nom ou adresse"
                    clearable
                    prepend-icon="mdi-magnify"
                    @input="filterTubes"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4"
            class="pt-0 pt-sm-3">
                <v-select
                    label="Commune"
                    v-model="muni"
                    :items="getMunisArr"
                    item-text="name"
                    item-value="value"
                    clearable
                    prepend-icon="mdi-filter"
                    @change="filterTubes"
                />
            </v-col>
        </v-row>

        <v-alert
        v-if="!keytubes.length"
        type="info"
        text
        class="mt-5">
            Aucun cylindre à afficher
        </v-alert>

        <v-alert
        v-else-if="!filteredTubes.length"
        type="info"
        text
        class="mt-5">
            Aucun cylindre correspond aux critères de recherche
        </v-alert>

        <v-list
        v-else
        two-line>
            <v-list-item v-for="(tube, i) in filteredTubes" :key="tube.id"
            :to="{ name: 'Keytube details', params: {id: tube.id} }"
            :style="i === 0 ? '' : 'border-top: 1px solid lightgrey'">
                <v-list-item-content>
                    <v-list-item-title v-text="tube.title"
                    class="text-wrap text-h6"/>
                    <v-list-item-subtitle v-text="tube.address"
                    class="text-wrap"/>
                </v-list-item-content>

                <v-list-item-avatar tile size="60">
                    <v-img :src="getMunis[tube.muni].svg" contain eager/>
                </v-list-item-avatar>
            </v-list-item>
        </v-list>
    </v-container>
</template>

<script>
import { MUNIS } from '@/constants/places';
import { collection, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore';
const db = getFirestore();
let dbUnsub;

export default {
    name: 'mKeytubesList',
    data: () => ({
        loading: true,
        keytubes: [],
        filteredTubes: [],
        search: '',
        muni: '',
    }),
    computed: {
        getMunis() {
            return MUNIS;
        },
        getMunisArr() {
            return Object.values(MUNIS);
        }
    },
    methods: {
        filterTubes() {
            let keytubes = [...this.keytubes];

            if (this.muni) {
                const muni = this.muni;
                keytubes = keytubes.filter(t => t.muni === muni);
            }

            if (this.search) {
                const search = this.search.toLowerCase();
                keytubes = keytubes.filter(t => (
                    t.title.toLowerCase().includes(search)
                    ||
                    t.address.toLowerCase().includes(search)
                ));
            }

            this.filteredTubes = keytubes;
        }
    },
    mounted() {
        let keytubes = [];
        const q = query(collection(db, 'keytubes'), orderBy('city'), orderBy('title'));
        dbUnsub = onSnapshot(q, snap => {
            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                keytubes.push(data);
            });
            // console.log(keytubes);
            this.keytubes = keytubes;
            this.filterTubes();
            this.loading = false;
        }, error => {
            this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
        });
    },
    updated() {
    },
    destroyed() {
        if (dbUnsub) dbUnsub();
    }
}
</script>

<style>

</style>