<template>
    <v-container>
        <v-progress-linear v-if="loading" indeterminate color="accent" />
        <v-card v-else
        flat
        max-width="600px" class="mx-auto">

            <m-keytube-top-buttons/>

            <v-card-title v-text="keytube.title" class="wordbreak-normal" />

            <v-divider/>

            <v-card-text :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" style="font-size: 1rem;">
                <m-keytube-basic-info :keytube="keytube"/>

                <v-divider class="my-3"/>

                <m-keytube-remarks :remarks="keytube.remarks"/>

                <m-keytube-documents :keytube="keytube"/>

                <div v-if="hasPhotos">
                    <v-divider class="my-3"/>
                    <span class="font-weight-bold">Images</span>
                    <m-keytube-photos :value="keytube"/>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import mKeytubeTopButtons from '../components/keytube/topButtons.vue';
import mKeytubeBasicInfo from '../components/keytube/basicInfo.vue';
import mKeytubePhotos from '../components/keytube/photos.vue';
import mKeytubeRemarks from '../components/keytube/remarks.vue';
import mKeytubeDocuments from '../components/keytube/documents.vue';

import { doc, getFirestore, onSnapshot } from "firebase/firestore";
const db = getFirestore();
let dbUnsub;

export default {
    name: 'mKeytube',
    components: {mKeytubeTopButtons,mKeytubeBasicInfo, mKeytubePhotos, mKeytubeRemarks, mKeytubeDocuments},
    data: () => ({
        keytube: {},
        loading: true,
    }),
    computed: {
        hasPhotos() {
            return !!this.keytube.pics.length;
        }
    },
    mounted() {
        const id = this.$route.params.id;
        let keytube = {};
        dbUnsub = onSnapshot(doc(db, 'keytubes', id), doc => {
            keytube = doc.data();
            keytube.id = doc.id;
            this.keytube = keytube;
            this.loading = false;
        }, error => {
            this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
            this.loading = false;
        });
    },
    destroyed() {
        if (dbUnsub) dbUnsub();
    }
}
</script>

<style>

</style>