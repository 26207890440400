<template>
    <div>
        <span class="font-weight-bold">Documents</span>

        <v-row class="pt-0 mt-0">
            <v-col cols="12" sm="6" class="py-0 mb-1">
                <v-btn color="accent"
                :disabled="!maps.exists"
                :loading="maps.loading"
                :href="maps.url"
                target="_Blank"
                large
                block
                class="mt-2">
                    Dossier d'intervention
                    <v-icon small right>mdi-file-multiple</v-icon>
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6" class="py-0 mb-3">
                <v-btn color="accent"
                :disabled="!pbc.exists"
                :loading="pbc.loading"
                :href="pbc.url"
                target="_Blank"
                large
                block
                class="mt-2">
                    PBC 
                    <v-icon right>mdi-bank</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
const storage = getStorage();

export default {
    name: 'mKeytubeDocuments',
    props: ['keytube'],
    data: () => ({
        maps: {
            exists: false,
            url: '',
            loading: true,
        },
        pbc: {
            exists: false,
            url: '',
            loading: true,
        },
    }),
    computed: {
    },
    created() {
        const tubeRef = ref(storage, `keytubes/${this.keytube.id}/docs`);
        const files = ['maps', 'pbc'];

        files.forEach(file => {
            const fileName = `doc-${file}.pdf`;
            const fileRef = ref(tubeRef, fileName);

            // list files
            // -> check for doc & get URL
            // -> fetch
            // -> get blob
            // -> create URL blob
            // -> stop loading

            listAll(tubeRef).then(list => {
                const hasFile = list.items.some(v => v.name == fileName);
                if (hasFile)
                    return getDownloadURL(fileRef);
                throw null;
            }).then(url => {
                return fetch(url, {mode: 'cors'});
            }, err => {
                throw err;
            }).then(res => {
                if (res.ok)
                    return res.blob();
                else
                    throw { code: 'fetch-fail', message: 'Erreur lors du téléchargement' };
            }).then(res => {
                this[file].url = URL.createObjectURL(res);
                this[file].exists = true;
            }).catch(err => {
                if (err != null && err.code != 'storage/object-not-found')
                    this.$store.commit('setError', { type: 'firebase-storage', code: err.code, message: err.message });
            }).finally(() => {
                this[file].loading = false;
            });
            
        });
    }
}
</script>
