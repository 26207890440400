<template>
    <div>
        <v-row align="center">
            <!-- text -->
            <v-col>
                <div class="mb-1">
                    <span class="font-weight-bold">Adresse</span>
                    <br>
                    {{ keytube.address }}
                </div>
                <div class="mb-1">
                    <span class="font-weight-bold">Localité</span>
                    <br>
                    {{ getTown }}
                </div>
                <div>
                    <span class="font-weight-bold">Commune</span>
                    <br>
                    {{ getMunis[keytube.muni].name }}
                </div>
            </v-col>

            <!-- coat of arms + map btn -->
            <v-col cols="auto" class="text-center">
                <v-img
                :src="getMunis[keytube.muni].svg"
                contain
                :width="$vuetify.breakpoint.smAndUp ? '4rem' : '3rem'"/>

                <v-btn
                class="mt-2"
                icon
                x-large
                @click="clickGoTo">
                    <v-icon>mdi-map-search</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { MUNIS, TOWNS } from '@/constants/places';

export default {
    name: 'mKeytubeBasicInfo',
    props: {
        keytube: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getMunis() {
            return MUNIS;
        },
        getTown() {
            if (this.keytube.town) {
                const townInfo = TOWNS[this.keytube.town];
                return `${townInfo.npa} ${townInfo.name}`
            }
            return 'Indisponible';
        },
    },
    methods: {
        clickGoTo() {
            const loc = this.keytube.location;
            const link = `https://www.google.com/maps/search/?api=1&query=${loc.latitude}%2C${loc.longitude}`;
            window.open(link, '_Blank', 'noopener noreferrer');
        }
    }
}
</script>

<style>

</style>