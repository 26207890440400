<template>
    <div>
        <v-skeleton-loader :type="`card@${nPhotos}`" v-if="loading"/>
        
        <v-card v-for="(photo, i) in photos" :key="'photo'+i"
            class="my-5"
            elevation="10"
        >
            <v-img
                :src="photo.url"
                contain
                @load="photoLoaded(i)"
                eager
            />
            <v-card-title  v-text="photo.caption" />
        </v-card>
    </div>
</template>

<script>
//getDownloadURL
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from 'firebase/storage';
const storage = getStorage();

export default {
    name: 'mKeytubePhotos',
    props: ['value'],
    data: () => ({
        photos: [],
        loading: true,
        imgLoads: [],
        nPhotos: 3,
    }),
    methods: {
        photoLoaded(i) {
            this.$set(this.imgLoads, i, true);
            if (this.imgLoads.every(v => v == true))
                this.loading = false;
        }
    },
    async mounted() {
        const folderRef = ref(storage, `keytubes/${this.value.id}`);
        let photos = [];
        let promiseArr = [];

        const list = await listAll(folderRef);
        this.nPhotos = list.items.length;

        promiseArr = list.items.map(async (item, i) => {
            try {
                const url = await getDownloadURL(item);
                const meta = await getMetadata(item);
                photos[i] = {
                    url: url,
                    fileName: meta.name || '',
                    caption: meta.mCaption ?? this.value.pics[i].caption ?? '',
                    order: meta.mOrder ?? 0,
                }
            } catch (error) {
                this.loading = false;
                this.$store.commit('setError', { type: 'firebase-storage', code: error.code, message: error.message });
            }
        });

        Promise.all(promiseArr).then(() => {
            photos.sort((a, b) => { Number(a.order) - Number(b.order) });
            this.photos = photos;
        });

        // let photos = this.value.pics || [];
        // const folderRef = ref(storage, `keytubes/${this.value.id}`);
        // let promiseArr = [];

        // photos.forEach((photo, i) => {
        //     const childRef = ref(folderRef, photo.fileName);
        //     promiseArr[i] = getDownloadURL(childRef).then(url => {
        //         photos[i].url = url;
        //     }).catch(error => {
        //         this.$store.commit('setError', { type: 'firebase-storage', code: error.code, message: error.message });
        //     });
        // });

        // Promise.all(promiseArr).then(() => {
        //     this.photos = photos;
        // });
    }
}
</script>

<style>

</style>