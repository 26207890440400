<template>
    <v-card-actions>
        <v-btn color="secondary"
        :to="{name: 'Keytubes map'}"
        small>
            <v-icon :left="big">mdi-map</v-icon>
            {{ big ? "Carte" : "" }}
        </v-btn>

        <v-btn color="secondary"
        :to="{name: 'Keytubes list'}"
        small>
            <v-icon :left="big">mdi-format-list-bulleted-square</v-icon>
            {{ big ? "Liste" : "" }}
        </v-btn>

        <v-spacer/>

        <v-btn 
        v-if="canEdit"
        color="secondary"
        :to="{name: 'Keytubes list'}"
        small>
            <v-icon :left="big">mdi-pencil</v-icon>
            {{ big ? "Modifier" : "" }}
        </v-btn>
    </v-card-actions>
</template>

<script>
export default {
    name: 'mKeytubeTopButtons',
    computed: {
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
        canEdit() {
            return this.$store.getters.canAccess('keytubes_admin', 'keytubes_mod');
        }
    }
}
</script>

<style>

</style>