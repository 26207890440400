<template>
    <div v-if="!!remarks">
        <span class="font-weight-bold">Remarques</span>
        <br>
        <v-alert v-if="importantRemarks"
        type="error" 
        class="font-weight-bold mt-2">
            {{ remarks }}
        </v-alert>
        <span v-else>{{ remarks }}</span>
        <v-divider class="my-3"/>
    </div>
</template>

<script>
export default {
    name: 'mKeytubeRemarks',
    props: ['remarks'],
    computed: {
        importantRemarks() {
            return this.remarks === this.remarks.toUpperCase();
        }
    }
}
</script>

<style>

</style>